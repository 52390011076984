body {
  background-color: #1e1e1e;
}
.game-area {
  position: relative;
  width: 500px;
  height: 400px;
  border: 2px solid #dc042c;
  border-radius: 10px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 10px #abbfc0;
}

@media only screen and (max-width: 800px) {
  .game-area {
    position: relative;
    width: 350px;
    height: 300px;
  }

  .snake {
    width: 12px;
    height: 12px;
  }
}

.snake {
  position: absolute;
  width: 2%;
  height: 2%;
  background-color: #07ff28;
  border: 1px solid white;
  z-index: 2;
}

.food {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 20px;
  z-index: 1;
}
